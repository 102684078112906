<template>
  <main class="max-w-7xl mx-auto ">
    <div class="lg:grid lg:grid-cols-12 lg:gap-x-5">
      <!-- Payment details -->
      <div class="space-y-6 lg:px-0 lg:col-span-12">
        <div class="bg-white pt-6 space-y-6 shadow rounded-md overflow-hidden">
          <div class="px-4 sm:px-6 flex flex-row justify-between items-center">
            <h2
              id="team_members"
              class="text-lg leading-6 font-semibold text-gray-900"
            >
              Social Connections
            </h2>
          </div>
          <div class="flex flex-col">
            <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div
                class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8"
              >
                <div class="overflow-hidden border-t border-gray-200">
                  <table class="min-w-full divide-y divide-gray-200">
                    <thead>
                      <tr>
                        <th
                          class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                        >
                          <span class="block ml-12"> Name</span>
                        </th>
                        <th
                          class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Connected Since
                        </th>
                        <th
                          class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Utilized By
                        </th>
                        <th
                          class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Status
                        </th>
                        <th class="px-6 py-3 bg-gray-50"></th>
                      </tr>
                    </thead>
                    <tbody class="bg-white divide-y divide-gray-200">
                      <tr
                        v-for="account in connections"
                        v-bind:key="account.id"
                      >
                        <td class="px-6 py-4 whitespace-no-wrap">
                          <div class="flex items-center">
                            <div class="w-8 flex justify-center">
                              <img
                                class="w-4 h-4"
                                src="https://facebookbrand.com/wp-content/uploads/2019/04/f_logo_RGB-Hex-Blue_512.png?w=512&h=512"
                                v-if="account.type == 'facebook'"
                              />
                              <img
                                class="w-auto h-4"
                                src="@/assets/3rd-party/yt-icon.png"
                                v-if="account.type == 'youtube'"
                              />
                            </div>
                            <div
                              class="text-sm leading-5 font-medium text-gray-900 flex flex-row content-center ml-4"
                            >
                              {{ account.display_name }}
                            </div>
                          </div>
                        </td>
                        <td class="px-6 py-4 whitespace-no-wrap">
                          <div class="text-sm leading-5 text-gray-900">
                            {{ account.created_at | displayDateTime }}
                          </div>
                        </td>
                        <td class="px-6 py-4 whitespace-no-wrap space-x-2">
                          <span
                            class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-600 text-white"
                            v-for="team in account.teams"
                            v-bind:key="team.id"
                            >{{ team.name }}</span
                          >
                        </td>
                        <td class="px-6 py-4 whitespace-no-wrap">
                          <span
                            class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800"
                            v-if="account.isExpired"
                          >
                            Expired
                          </span>
                          <span
                            class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800"
                            v-if="!account.isExpired"
                          >
                            Active
                          </span>
                        </td>
                        <td
                          class="px-6 py-4 whitespace-no-wrap text-right text-sm leading-5 font-medium"
                        >
                          <a href="#" class="text-blue-600 hover:text-blue-900"
                            >Edit</a
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";

import General from "@/screens/App/Settings/General";
import Watermark from "@/screens/App/Settings/Watermark";
import TeamMembers from "@/screens/App/Settings/TeamMembers";
import SocialAccounts from "@/screens/App/Settings/SocialAccounts";

export default {
  name: "SettingsPage",
  components: { TeamMembers, Watermark, General, SocialAccounts },
  data() {
    return {
      connections: [],
    };
  },
  computed: {
    ...mapState("auth", {
      currentUser: (state) => state.userData,
    }),
    ...mapState("team", {
      currentTeam: (state) => state.name,
      currentTeamId: (state) => state.id,
      users: (state) => state.users,
    }),
  },
  mounted() {
    this.getUsersSocialConnections();
  },
  methods: {
    async getUsersSocialConnections() {
      let res = await this.$http.get(
        process.env.VUE_APP_API_URL + "/app/user/connections"
      );
      this.connections = res.data;
    },
  },
};
</script>
